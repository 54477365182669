import { graphql } from "gatsby";
import { block } from "bem-cn";

import Layout from "layouts/";
import Heading from "components/Heading";
import Title from "components/Title";
import Container from "components/Container";
import Image from "components/Image";

import { paragraph, renderAst } from "components/markDownComponents";

const renderComToolkit = {
  p: paragraph,
};

const renderCom = renderAst(renderComToolkit);

import "./style.scss";

const cn = block("note-from-founder-page");

const NoteFromFounderPage = ({ data }) => {
  const { htmlAst } = data.markdownRemark;
  const { seo, title, bannerDescription, img, alt, person } =
    data.markdownRemark.frontmatter;

  return (
    <Layout seo={seo}>
      <Container className={cn("heading", { wrap: true })}>
        <Heading
          color="general"
          weight="bold"
          align="center"
          className={cn("heading", { title: true })}
        >
          {title}
        </Heading>
      </Container>
      <Container className={cn("wrap")}>
        <figure className={cn("image", { wrap: true })}>
          <Image src={img} alt={alt} className={cn("image", { img: true })} />
          <figcaption>
            <Title color="secondary" level={9} weight="medium">
              {person}
            </Title>
          </figcaption>
        </figure>
        <div className={cn("content")}>{renderCom(htmlAst)}</div>
      </Container>
    </Layout>
  );
};

export default NoteFromFounderPage;

export const query = graphql`
  query getNoteFromFounderPageData {
    markdownRemark(frontmatter: { slug: { eq: "/note-from-founder" } }) {
      htmlAst
      frontmatter {
        title
        bannerDescription
        seo {
          description
          image
          link
          title
        }
        img
        alt
        person
      }
    }
  }
`;
